import { useCombobox } from 'downshift';
import React, { Children, PropsWithChildren, ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react';
import { AutocompleteItemOption } from './types';
import clsx from 'clsx/lite';
import AutocompleteItem from './autocompleteItem';

type AutocompleteProps = PropsWithChildren<
  {
    options: AutocompleteItemOption[];
    inputValueRef: string;
    setInputValueRef: (input: string, coordinates?: number[]) => void;
    id: string;
    shouldOpenMenu?: boolean;
    setShouldOpenMenu?: (val: boolean) => void;
    variant?: 'header' | 'page' | 'widget' | 'form';
    enable?: boolean;
    fallbackComponent?: ReactNode;
  } & (
    | { shouldOpenMenu: boolean; setShouldOpenMenu: (val: boolean) => void }
    | { shouldOpenMenu?: undefined; setShouldOpenMenu?: undefined }
  )
>;

const Autocomplete = ({
  children,
  options,
  inputValueRef,
  setInputValueRef,
  shouldOpenMenu,
  setShouldOpenMenu,
  id,
  variant = 'widget',
  enable = true,
  fallbackComponent,
}: AutocompleteProps) => {
  const [items, handleItems] = useState(options);

  const updateInputValueRef = (newValue: string, extra?: { coordinates?: number[] }) =>
    extra?.coordinates ? setInputValueRef(newValue, extra.coordinates) : setInputValueRef(newValue);

  const combobox = useCombobox({
    onInputValueChange: ({ inputValue = '' }) => {
      updateInputValueRef(inputValue, { coordinates: options.find(opt => opt.value === inputValue)?.coordinates });
      handleItems(options);
    },
    items: items.map(item => item.value),
    inputValue: inputValueRef,
    onSelectedItemChange: changes => {
      if (!enable || !changes.inputValue || !options.find(opt => opt.value === changes.inputValue)) return;
      updateInputValueRef(changes.inputValue);
    },
    id: id,
    inputId: `${id}-input`,
    labelId: `${id}-label`,
    menuId: `${id}-items-list`,
    getItemId: index => `${id}-item-${index}`,
    initialHighlightedIndex: !enable || !inputValueRef ? -1 : 0,
  });

  useEffect(() => {
    if (shouldOpenMenu) {
      combobox.openMenu();
      setShouldOpenMenu(false);
    }
  }, [combobox, shouldOpenMenu, setShouldOpenMenu]);

  useEffect(() => {
    handleItems(options);
    combobox.setHighlightedIndex(!enable || !inputValueRef ? -1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combobox.inputValue, inputValueRef, options]);

  if (!children) return null;

  return (
    <div className="relative h-full w-full" data-cy="autocomplete" aria-label={`${id}-label`}>
      {Children.map(children, child =>
        isValidElement(child) ? cloneElement(child as React.ReactElement<any>, { ...combobox.getInputProps() }) : child,
      )}
      <ul
        className={clsx(
          'absolute z-50 mt-2 max-h-96 w-full overflow-y-auto overflow-x-hidden rounded-md bg-neutral-surface text-slate-500 shadow-xl',
          variant === 'page' && 'md:w-[20rem] lg:w-[34rem]',
          variant === 'header' && 'md:w-[375px] lg:w-[460px] xl:w-[528px]',
          variant === 'form' && 'w-[24rem] md:w-[34rem]',
          combobox.isOpen && !!items.length && 'z-[1000] py-3',
        )}
        {...combobox.getMenuProps()}
      >
        {fallbackComponent ??
          (combobox.isOpen &&
            items.map((item, index) => (
              <AutocompleteItem
                key={`${item.value}-${index}`}
                item={item}
                index={index}
                combobox={combobox}
                isHighlighted={combobox.highlightedIndex === index}
              />
            )))}
      </ul>
    </div>
  );
};

export default Autocomplete;
